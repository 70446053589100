<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">smart-whats.com</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div v-if="step == 2">
            <div class="alert alert-success text-center g">
                تم ارسال كود التفعيل على الواتساب التالي {{ phone }}
            </div>
            <div class="form-group">
            <label for="">اكتب كود التفعيل</label>
            <input type="text"
                class="form-control" v-model="code" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
            <label for="">كلمة المرور الجديدة</label>
            <input type="password"
                class="form-control" v-model="password" placeholder="اكتب هنا...">
            </div>
            <button class="btn btn-primary" @click="done()">
                تغيير كلمة المرور الآن
            </button>
        </div>
        <div v-if="step == 1">
            <div class="form-group">
            <label for="">اكتب رقم الجوال</label>
            <input type="text"
                class="form-control" autofocus v-model="phone" placeholder="اكتب هنا...">
            </div>
            <button class="btn btn-primary" @click="send()">
                ارسال كود استعادة كلمة المرور
            </button>
        </div>
        <div class="col-12 text-center">
            <br>
            اذا واجهت مشكلة تواصل مع الدعم الفني.
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      phone: "",
      code: "",
      password: "",
      step: 1,
    };
  },
  methods: {
    send() {
      var g = this;
      if(!this.phone){
        alert("اكتب رقم الجوال")
        return;
      }
      $.post(api + "/user/auth/reset", {
        phone: this.phone
      })
        .then(function (response) {
          if (response.status == 100) {
            g.step = 2
          } else {
            g.step = 1
            g.$toast({
              component: ToastificationContent,
              props: {
                title: response.response,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          alert("Error:" + e);
        });
    },
    done() {
      var g = this;
      if(!this.phone){
        alert("اكتب رقم الجوال")
        return;
      }
      $.post(api + "/user/auth/reset-done", {
        phone: this.phone,
        code: this.code,
        password: this.password
      })
        .then(function (response) {
          if (response.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم تغيير كلمة المرور بنجاح",
                icon: "EditIcon",
                variant: "success",
              },
            });
            setTimeout(() => {
                window.location = '/login'
            }, 2000);
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: response.response,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          alert("Error:" + e);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
